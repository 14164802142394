<template>
  <v-container class="w-100 px-0" fluid>
    <template v-if="pageContent != null">
      <!-- HEADER -->
      <v-row class="mx-0 px-0">
        <v-img
          :src="`${apiPublicUrl}${pageContent.image}`"
          min-height="600px"
          max-height="600px"
          class="header blur overlay-1"
          style="background-position:top center;"
        >
          <v-row class="fill-height mt-3" align="center" justify="center">
            <div class="white--text text-center">
              <h1
                class="
                  text-lg-h1 text-md-h2 text-sm-h3 text-h4 text-uppercase
                  li-text-shadow
                  liHeroHeading
                  mb-2
                  font-weight-bold
                "
                v-html="pageContent.headerTitle"
              />
              <div
                class="text-sm-h5 text-p white--text text-center li-text-shadow font-weight-bold"
                v-html="pageContent.headerSubTitle"
              />
              <v-btn class="btn-cta mt-5" :to="`${pageContent.hqlink}`">
                 {{ pageContent.hqtitle }}
              </v-btn>
              <v-btn class="btn-cta mt-5" :to="`${pageContent.hqlink}`">
                 {{ pageContent.retailtitle }}
              </v-btn>
            </div>
            
          </v-row>
        </v-img>
      </v-row>
      <!-- CONTENT -->
      <!-- WE ARE -->
      <v-row class="mx-0 px-0">
        <v-container class="py-10 my-10" style="max-width: 1400px">
          <v-row>
            <v-col cols="12" md="6" lg="4" class="d-flex">
              <div class="my-auto">
                <h2
                  class="text-md-h4 text-h4 mb-3 liHeroHeading"
                  v-html="pageContent.wearetitle"
                />
                <div
                  class="text-p mt-7 mb-7 "
                  v-html="pageContent.wearetext"
                />
              </div>
            </v-col>
            <template
              v-if="pageContent.videoCode && pageContent.videoCode != ''"
            >
              <v-col cols="12" md="6" lg="8" >
                <div class="bg-white pa-3 elevation-10 rounded-10">
                  <liVimeoVideo :vimeoId="pageContent.videoCode" />
                </div>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-row>
      <!-- JOBS -->
      <v-row class="mx-0 px-0 bg-black">
        <v-container class="py-10" style="max-width: 1400px">
          <!-- JOBS TITLE -->
          <v-row class="py-5">
            <v-col cols="12" sm="12" md="12" lg="4" class="d-flex">
              <div class="my-auto ">
                <h2
                  class="text-md-h4 text-h4 mb-3 font-weight-bold text-white liHeroHeading"
                  v-html="pageContent.jobstitle"
                />
                <div
                  class="text-p mt-7 mb-7 text-white"
                  v-html="pageContent.jobssubtitle"
                />
              </div>
            </v-col>
            <!-- JOB CATEGORIES  -->
            <v-col class="m-auto" sm="12" md="6" lg="4" xl="4" cols="12">
              <v-hover v-slot="{ hover }">
                <v-card :to="`${pageContent.hqlink}`">
                  <v-img
                    :aspect-ratio="1 / 1"
                    :src="`${apiPublicUrl}${pageContent.hqimage}`"
                  >
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out primary darken-2 v-card--reveal text-h2 white--text font-weight-bold"
                        style="height: 100%"
                      >
                        {{ pageContent.hqtitle }}
                      </div>
                    </v-expand-transition>
                  </v-img>
                  <v-card-text class="text-black" style="position: relative">
                    <v-btn absolute  fab large right top class="text-white bg-primary">
                      <v-icon>mdi-arrow-right</v-icon>
                    </v-btn>
                    <h3 class="text-h5 text-sm-h4 mt-4">
                      {{ pageContent.hqtitle }}
                    </h3>
                    <div
                      class="text-p"
                      v-html="pageContent.hqtext"
                    ></div>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
            <v-col class="m-auto" sm="12" md="6" lg="4" xl="4" cols="12">
              <v-hover v-slot="{ hover }">
                <v-card
                  :to="`${pageContent.retaillink}`"
                  color="grey lighten-4"
                >
                  <v-img
                    :aspect-ratio="1"
                    :src="`${apiPublicUrl}${pageContent.retailimage}`"
                  >
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out primary darken-2 v-card--reveal text-h2 white--text font-weight-bold"
                        style="height: 100%"
                      >
                        {{ pageContent.retailtitle }}
                      </div>
                    </v-expand-transition>
                  </v-img>
                  <v-card-text class="text-black" style="position: relative">
                   <v-btn absolute  fab large right top class="text-white bg-primary">
                      <v-icon>mdi-arrow-right</v-icon>
                    </v-btn>
                    <h3 class="text-h5 text-sm-h4 mt-4">
                      {{ pageContent.retailtitle }}
                    </h3>
                    <div
                      class="text-p"
                      v-html="pageContent.retailtext"
                    ></div>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
      <!-- CULTURE -->
      <v-row class="mx-0 px-0 bg-light">
        <v-container class="pb-16 pt-16" style="max-width: 1400px">
          <!-- CULTURE TITLE -->
          <v-row class="pb-5 pt-16">
            <v-col cols="12" md="12" lg="12" class="m-auto">
              <div class="mt-5 text-center text-black">
                <h2
                  class="text-md-h4 text-h4 mb-3 text-black"
                  v-html="pageContent.culturetitle"
                />
                <div
                  class="text-p text-center mb-7"
                  v-html="pageContent.culturetext"
                />
              </div>
            </v-col>
          </v-row>
          <!-- CULTURE ITEMS  -->
          <liCultureItems v-if="cultureItems != null" :key="fullUrl" :itemsToShow="4" />
        </v-container>
      </v-row>
    </template>
    <template v-else>
      <div class="text-center w-100 d-flex" style="height: 50vh">
        <v-progress-circular indeterminate color="primary" class="m-auto" />
      </div>
    </template>
  </v-container>
</template>

<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
import { mapState } from "vuex";
export default {
  title: "Home",
  name: "Home",
  data() {
    return {
      apiPublicUrl: API_URL,
    };
  },
  created() {
    this.fetchURL();
  },
  watch: {
    page(val) {
      if (val != null) {
        this.$title = this.page.pagetitle;
      }
    }
  },
  mounted() {
    // console.log("START -- HOME VIEW");
    // console.log("language", this.language);
    // console.log("website", this.website);
    // console.log("fullUrl", this.fullUrl);
    // console.log("cultureItems", this.cultureItems);
    // console.log("END -- HOME VIEW");
    this.fetchPageData();
    if (this.cultureItems == null) {
      this.fetchCultureItems();
    }
  },
  computed: {
    ...mapState("liApiContent", ["website"]),
    page() {
      return this.$store.getters["liApiContent/getPage"](this.fullUrl);
    },
    pageContent() {
      return this.page != null ? JSON.parse(this.page.content) : null;
    },
  },
  methods: {},
};
</script>
